<template>
  <div class="brand-header">
    <div class="brand-header-content">
      <div class="brand-logo">
        <div class="image-container">
          <img :src="logoPath" />
        </div>
      </div>
      <div class="brand-detail">
        <div class="brand-name">
          {{ brandInfo.name }}
        </div>
        <div class="brand-description">
          {{ brandInfo.longDescription }}
        </div>
      </div>
    </div>
  </div>
  <div v-if="brandInfo.catalogs" class="brand-catalogs">
    <div
      v-for="catalog in brandInfo.catalogs"
      :key="catalog"
      class="catalog-container"
    >
      <div class="catalog-name">{{ catalog.name }}</div>
      <div class="catalog-products">
        <div
          class="catalog-product"
          v-for="product in catalog.products"
          :key="product"
        >
          <transition name="catalog-product-transition" appear>
            <div class="catalog-product-transition">
              <div class="product-image" v-if="product.image">
                <div class="container">
                  <img :src="getProductImage(product.image)" />
                </div>
              </div>
              <div class="product-information">
                <div class="name">{{ product.name }}</div>
                <div class="description">{{ product.description }}</div>
                <div class="url">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    :href="product.url"
                    >View Product Information</a
                  >
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
  <div v-if="brandInfo.url" class="brand-learn-more">
    <div>
      <div class="brand-learn-more-button">
        <a target="_blank" rel="noopener noreferrer" :href="brandInfo.url">
          VISIT {{ upperCaseBrandName }} HOMEPAGE
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BrandDetail",
  props: ["brandInfo"],
  computed: {
    logoPath() {
      return require("../assets/internal/logo/" + this.brandInfo.logo);
    },
    upperCaseBrandName() {
      return this.brandInfo.name.toUpperCase();
    },
  },
  methods: {
    getProductImage(fileName) {
      return require("../assets/internal/product/" + fileName);
    },
  },
};
</script>

<style lang="scss" scoped>
$mobile-max-width: 800px;

.brand-header {
  border-top: 1px solid #ddd;
  padding: 60px 40px 40px 40px;

  @media (max-width: $mobile-max-width) {
    padding: 30px 30px 0px 30px;
  }
}

.brand-header-content {
  display: flex;
  width: 100%;
  max-width: 1500px;
  margin: auto;

  @media (max-width: $mobile-max-width) {
    flex-direction: column;
  }

  .brand-logo {
    flex: 1;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    .image-container {
      display: flex;
      width: 50%;
      padding: 10px;
      box-shadow: 0 0 15px #ccc;

      @media (max-width: $mobile-max-width) {
        padding: 20px;
        max-width: 140px;
      }

      img {
        width: 100%;
        height: auto;
        background-color: #fff;
      }
    }
  }

  .brand-detail {
    flex: 3;
    display: flex;
    flex-direction: column;

    .brand-name {
      flex: 1;
      font: 500 40px "Lexend";
      letter-spacing: 0.07em;
      text-transform: uppercase;
      color: #045269;

      @media (max-width: $mobile-max-width) {
        font: 24px "Lexend";
        padding-top: 25px;
      }
    }

    .brand-description {
      flex: 2;
      font: 22px "Zen Kaku Gothic New";
      letter-spacing: 0.05em;
      line-height: 1.5;
      color: #333;
      margin: auto 0;
      padding: 10px 0;

      @media (max-width: $mobile-max-width) {
        font: 14px "Zen Kaku Gothic New";
      }
    }
  }
}

.brand-catalogs {
  display: flex;
  flex-direction: column;
  max-width: 1500px;
  margin: auto;

  @media (max-width: $mobile-max-width) {
    font: 24px "Lexend";
  }

  .catalog-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 30px;

    .catalog-name {
      font: 32px "Lexend";
      color: #333;
      padding: 30px;

      @media (max-width: $mobile-max-width) {
        font: 24px "Lexend";
        padding: 10px;
      }
    }

    .catalog-products {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 50px;

      @media (max-width: $mobile-max-width) {
        grid-template-columns: repeat(1, 1fr);
      }

      .catalog-product {
        .product-image {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 20px;

          .container {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 100%;
              max-width: 240px;

              @media (max-width: $mobile-max-width) {
                width: 60%;
              }
            }
          }
        }

        .product-information {
          flex: 3;
          display: flex;
          flex-direction: column;
          padding: 20px;

          .name {
            flex: 1;
            font: 22px "Oswald";
            color: #045269;

            @media (max-width: $mobile-max-width) {
              font: 20px "Oswald";
            }
          }

          .description {
            flex: 3;
            font: 16px "Zen Kaku Gothic New";
            padding: 10px 0;

            @media (max-width: $mobile-max-width) {
              font: 14px "Zen Kaku Gothic New";
            }
          }

          .url {
            margin-left: auto;
            flex: 0 30px;
            a {
              text-decoration: none;
              font: 600 16px "Zen Kaku Gothic New";
              color: #00a8e9;

              @media (max-width: $mobile-max-width) {
                font: 600 14px "Zen Kaku Gothic New";
              }
            }
          }
        }
      }
    }
  }
}

.brand-learn-more {
  max-width: 1500px;
  margin: auto;
  padding: 30px;

  .brand-learn-more-button {
    background-color: #045269;
    padding: 10px 20px;
    border-radius: 25px;

    @media (max-width: $mobile-max-width) {
      padding: 5px 10px;
      border-radius: 25px;
    }

    a {
      text-decoration: none;
      font: 500 22px "Zen Kaku Gothic New";
      color: #eafffc;

      @media (max-width: $mobile-max-width) {
        font: 500 16px "Zen Kaku Gothic New";
      }
    }
  }
}

.catalog-product-transition {
  display: flex;
  box-shadow: 0 0 15px #ccc;
  border-radius: 10px;
  height: 100%;

  @media (max-width: $mobile-max-width) {
    flex-direction: column;
  }
}

.catalog-product-transition-enter-from {
  opacity: 0;
  transform: scale(0.9);
}

.catalog-product-transition-enter-active {
  transition: all 1.2s ease;
}
</style>