<template>
  <header :style="cssProps">
    <nav>
      <div class="company-logo">
        <div class="company-logo-container">
          <router-link :to="{ name: 'Home' }">
            <div>
              <img alt="Primaservis Migasindo" src="@/assets/internal/logo/company.png" />
            </div>
          </router-link>
        </div>
      </div>
      <div v-if="!isMobileView" class="menu-list">
        <router-link
          v-for="(menu, index) in menus"
          :key="menu"
          :index="index"
          class="menu"
          :to="{ name: menu.routeName }"
        >
          <div :class="'menu-tab ' + isSelected(menu.title)">{{ menu.title }}</div>
        </router-link>
      </div>
      <div v-if="isMobileView" class="menu-list">
        <img
          class="mobile-menu-toggle"
          v-on:click="toggleMobileNav"
          src="@/assets/bars-regular.svg"
        />
      </div>
    </nav>
    <transition name="mobile-menu-container">
      <div class="mobile-menu-container" v-if="isMobileNavOpened">
        <div class="mobile-menu-list">
          <ul>
            <router-link
              v-for="(menu, index) in menus"
              :key="menu"
              :index="index"
              class="mobile-menu"
              :to="{ name: menu.routeName }"
              v-on:click="toggleMobileNav"
            >
              <div :class="'mobile-menu-tab ' + isSelected(menu.title)">{{ menu.title }}</div>
            </router-link>
          </ul>
        </div>
        <div
          class="outside-mobile-menu-list"
          v-on:click="toggleMobileNav"
        ></div>
      </div>
    </transition>
  </header>
</template>

<script>
export default {
  name: "Header",
  props: {
    mobileMaxWidth: Number,
  },
  data() {
    return {
      isMobileView: null,
      isMobileNavOpened: null,
      windowWidth: null,
      defaultMobileMaxWidth: 800,
    };
  },
  created() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
  },
  computed: {
    cssProps() {
      let mobileMaxWidth = this.mobileMaxWidth
        ? this.mobileMaxWidth
        : this.defaultMobileMaxWidth;
      return {
        "--mobile-max-width": mobileMaxWidth + "px",
      };
    },
    menus() {
      return this.$store.state.menu.data;
    },
  },
  methods: {
    checkScreen() {
      let mobileMaxWidth = this.mobileMaxWidth
        ? this.mobileMaxWidth
        : this.defaultMobileMaxWidth;
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= mobileMaxWidth) {
        this.isMobileView = true;
        return;
      }
      this.isMobileView = false;
      this.isMobileNavOpened = false;
    },
    toggleMobileNav() {
      this.isMobileNavOpened = !this.isMobileNavOpened;
    },
    isSelected(input) {
      let routeName = this.$route.name
      return routeName == input ? "selected" : ""
    }
  },
};
</script>

<style lang="scss" scoped>
$mobile-max-width: 800px;

header {
  font: 500 22px "Zen Kaku Gothic New";
  width: 100%;
  border-bottom: 1px solid #ddd;

  nav {
    display: flex;
    align-items: center;
    height: 80px;

    max-width: 1500px;
    margin: auto;
  }
}

.company-logo {
  height: 100%;
  .company-logo-container {
    height: 100%;
    display: flex;
    align-items: center;
    a {
      height: 100%;
      padding: 0px 10px;
      
      div {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      img {
        width: 100%;
        object-fit: contain;
        max-height: 70px;
      }
    }
  }
}

.menu-list {
  margin-left: auto;
  display: flex;
  align-items: center;
  padding: 0 30px;
  z-index: 140;
  height: 100%;

  @media(max-width: $mobile-max-width){
    background-color: #f4f4f4;
  }

  .menu {
    text-decoration: none;
    color: #333333;

    .menu-tab {
      padding: 5px 15px;
      margin: 0px 2.5px;
      border-radius: 15px;
      transition: all 1s ease;

      text-transform: uppercase;

      &:hover {
        transform: scale(1.1);
      }
    }

    .selected {
      font-weight: 600;
      color: #045269;
    }
  }
}

.mobile-menu-toggle {
  position: relative;
  z-index: 100;
  height: 30px;
}

.mobile-menu-container {
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;

  .mobile-menu-list {
    margin: 0px;
    padding: 0px;
    flex: 1 0 320px; // flex grow (ratio), flex shrink 0 (don't shrink), flex basis (initial size)
    max-width: 600px;

    background-color: #333333;

    ul {
      margin: 0px;
      padding: 0px;
    }

    .mobile-menu {
      font: 500 22px "Zen Kaku Gothic New";
      color: #dddddd;
      text-decoration: none;
      text-transform: uppercase;

      .mobile-menu-tab {
        padding: 15px 15px;
      }

      .selected {
        font-weight: 600;
        color: #eee
      }
    }
  }

  .outside-mobile-menu-list {
    flex-grow: 1;
    height: 100%;
  }
}

.mobile-menu-container-enter-active,
.mobile-menu-container-leave-active {
  transition: all 0.5s ease;
}

.mobile-menu-container-enter-from,
.mobile-menu-container-leave-to {
  transform: translateX(-600px);
}
</style>