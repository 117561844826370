<template>
  <div class="contact-page">
    <BannerSmall
      :banner-path="bannerPath"
      :opening-text="openingText"
      :title-text="titleText"
      brightness="0.4"
    />
    <div class="contact-page-content">
      <div class="contact-information">
        <div class="detail">
          <div class="section">
            <div class="title">Address</div>
            <div>PT. Primaservis Migasindo</div>
            <div>Jl. Plaju No.6</div>
            <div>Jakarta 10230, Indonesia</div>
          </div>
          <div class="section">
            <div class="title">Contact Information</div>
            <div>
              Phone:
              <a href="tel:+622131930801">+62 21 31930801</a>
              /
              <a href="tel:+622131930882">+62 21 31930882</a>
            </div>
            <div>Fax: +62 21 3904045</div>
            <div>
              Email:
              <a href="mailto:info@primaservis.com">info@primaservis.com</a>
            </div>
          </div>
        </div>
        <div class="map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3335.395194429634!2d106.82027708561283!3d-6.200802104207196!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc10f08a2abb96812!2sPrimaservis%20Migasindo.%20PT!5e0!3m2!1sen!2sid!4v1635231816033!5m2!1sen!2sid"
            width="100%"
            height="450"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BannerSmall from "@/components/BannerSmall.vue";

export default {
  name: "Contact",
  props: {
    isMobileView: Boolean,
  },
  components: { BannerSmall },
  data() {
    return {
      bannerPath: "bundaran-hi.jpg",
      openingText: "Always happy to hear from you",
      titleText: "Contact Us",
    };
  },
};
</script>

<style lang="scss" scoped>
$mobile-max-width: 800px;

.contact-page {
  background-color: #333;

  .contact-page-content {
    max-width: 1500px;

    margin: auto;
  }
}

.contact-information {
  display: flex;
  color: #eee;
  justify-content: center;
  align-items: center;
  padding: 20px;

  @media (max-width: $mobile-max-width) {
    flex-direction: column;
  }

  .detail {
    flex: 3;
    padding: 40px;

    @media (max-width: $mobile-max-width) {
      padding: 0 10px 20px 10px;
    }

    .section {
      padding-bottom: 50px;
      padding-left: 50px;

      @media (max-width: $mobile-max-width) {
        padding: 20px 10px;
      }
    }
  }

  .map {
    flex: 2;
    width: 100%;

    iframe {
      border-radius: 20px;
    }
  }
}

.title {
  font: 36px "Fjalla One";
  text-transform: uppercase;
  padding-bottom: 10px;

  @media (max-width: $mobile-max-width) {
    font: 24px "Fjalla One";
  }
}

.detail {
  font: 24px "Zen Kaku Gothic New";

  @media (max-width: $mobile-max-width) {
    font: 16px "Zen Kaku Gothic New";
  }

  a {
    text-decoration: underline;
    color: #eee;
    font-weight: 600;
  }
}
</style>