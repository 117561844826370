<template>
  <div class="container" v-if="!isMobileView">
    <div
      v-for="brand in brandList"
      :key="brand"
      :class="'brand-item ' + isSelected(brand.name)"
    >
      <router-link
        class="link"
        :to="{ name: 'Product', params: { brand: brand.name } }"
      >
        {{ brand.name }}
      </router-link>
    </div>
  </div>
  <div class="container" v-if="isMobileView">
    <div class="mobile-navigation">
      <select
        v-model="currentBrand"
        v-on:change="changeBrandMobile"
        class="mobile-brand-select"
      >
        <option disabled :value="currentBrand">{{ currentBrand }}</option>
        <option v-for="brand in otherBrandNameList" :key="brand" :value="brand">
          {{ brand }}
        </option>
      </select>
      <font-awesome-icon class="dropdown-icon" icon="caret-down" />
    </div>
  </div>
</template>

<script>
export default {
  name: "BrandNavigation",
  props: ["brandList", "isMobileView"],
  data() {
    return {
      currentBrand: null,
    };
  },
  created() {
    this.currentBrand = this.$route.params.brand;
    this.$watch(
      () => this.$route.params,
      (toParams, previousParams) => {
        this.currentBrand = toParams.brand;
      }
    );
  },
  computed: {
    otherBrandNameList() {
      let list = [];
      for (let key in this.brandList) {
        let brandName = this.brandList[key].name;
        if (brandName !== this.currentBrand) {
          list.push(brandName);
        }
      }
      return list;
    },
  },
  methods: {
    isSelected(input) {
      let brand = this.$route.params.brand;
      return brand == input ? "selected" : "";
    },
    changeBrandMobile() {
      this.$router.push({
        name: "Product",
        params: { brand: this.currentBrand },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$mobile-max-width: 800px;

.container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; //prevent overflow

  @media (max-width: $mobile-max-width) {
    margin: 10px 0px;
  }

  .brand-item {
    // height: 100%;
    display: flex;
    min-height: 50px;

    a {
      text-decoration: none;
      text-transform: uppercase;
      color: #045269;
      font: 600 18px "Zen Kaku Gothic New";
      padding: 0 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: $mobile-max-width) {
        padding: 0 15px;
      }
    }

    &.selected {
      // box-shadow: 0 -20px 30px -20px #000 inset;
      background-color: #045269;

      a {
        color: #fff;
        font: 600 18px "Zen Kaku Gothic New";
      }
    }
  }
}

.mobile-navigation {
  width: 50%;
  display: flex;
  align-items: center;
  background-color: #045269;
  border-radius: 5px;

  position: relative; //to make %left in dropdown-icon scale properly in container

  select:focus {
    outline: none !important;
  }

  .mobile-brand-select {
    width: 100%;
    text-transform: uppercase;
    color: #fff;
    font: 600 18px "Zen Kaku Gothic New";
    text-align: center;

    appearance: none;
    background-color: transparent;
    border: none;
    padding: 10px 15px;

    z-index: 90;

    option {
      color: #045269;
      background-color: #fff;
      font: 600 18px "Zen Kaku Gothic New";
      text-align: center;
    }
  }

  .dropdown-icon {
    color: white;
    position: absolute;
    left: calc(90%);
    z-index: 80;
  }
}
</style>