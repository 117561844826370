<template>
  <div class="reference-page">
    <BannerSmall 
    :banner-path="bannerPath"
    :title-text="titleText"
    brightness="0.5"
    />
    <div class="reference-page-content">
      <div class="image">
        <div class="image-grid-container">
          <div
            v-for="(company, index) in referenceCompanies"
            :key="company"
            :index="index"
            class="image-container"
          >
            <img :src="getLogo(company.logo)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BannerSmall from '@/components/BannerSmall.vue'

export default {
  name: "References",
  components: {BannerSmall},
  props: {
    isMobileView: Boolean,
  },
  data() {
    return {
      bannerPath: "indonesia.jpg",
      titleText: "Our References in Indonesia",
    }
  },
  computed: {
    referenceCompanies() {
      return this.$store.state.references.companies;
    },
  },
  methods: {
    getLogo(fileName) {
      return require("../assets/external/reference/" + fileName);
    },
  },
};
</script>

<style lang="scss" scoped>
$mobile-max-width: 800px;

.reference-page {
  background-color: #045269;

  .reference-page-content {
    max-width: 1500px;
    margin: auto;
  }
}

.image-grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 50px;
  padding: 50px;

  @media (max-width: $mobile-max-width) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    padding: 20px;
  }
}

.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  box-shadow: 0 0 15px #ccc;
  border-radius: 10px;
  background-color: #fff;

  @media (max-width: $mobile-max-width) {
    padding: 10px;
  }

  img {
    width: 100%;
    max-height: 200px;

    object-fit: contain;

    @media (max-width: $mobile-max-width) {
      max-height: 100px;
    }
  }
}
</style>