export default {
  state: {
    brand: [
      {
        name: "simtronics",
        description: "Simtronics offers a full range of products in Flame and Gas detection.",
        longDescription: "Simtronics (formerly known as Simrad) has supplied Flame and Gas Detection equipment for over 25 years. The Flame and Gas Detectors from Simtronics are designed with integrated alarm and control systems to protect lives and assets.",
        logo: "simtronics.jpg",
        route: { name: 'Product', params: { brand: 'simtronics' } },
        catalogs: [{
          name: "Combustible Gas Detectors",
          products: [{
            name: "GDP IR POINT GAS DETECTOR",
            description: "The Simtronics GD10P detector is the benchmark for combustible gas detection on offshore installations. It has set the standard for gas detectors in the areas of safety, stability and low maintenance. GD10P comes with 5 years warranty.",
            url: "https://www.teledynegasandflamedetection.com/en/gas-detectors/fixed/hazardous-area/gd10p",
            image: "simtronics-1.png"
          },
          {
            name: "GDP10PE IR EXTENDED POINT GAS DETECTOR",
            description: "The Simtronics GD10PE is designed for critical applications involving large volumes of air with high velocity. In places where you need fast reliable detection of low gas concentrations, GD10PE is in a class of its own.",
            url: "https://www.teledynegasandflamedetection.com/en/gas-detectors/fixed/hazardous-area/gd10p",
            image: "simtronics-2.jpg"
          }]
        },
        {
          name: "Toxic Gas Detectors",
          products: [{
            name: "MULTITOX DG-TT7-S MOS TOXIC GAS DETECTOR",
            description: "The Simtronics MultiTox DG-TT7-S intelligent solid-state (MOS) hydrogen sulphide (H2S) gas detector. The device has been designed for ease of operation and maintenance in hot and dry environments.",
            url: "https://www.teledynegasandflamedetection.com/en/gas-detectors/fixed/hazardous-area/dg7-series",
            image: "simtronics-3.jpg"
          },
          {
            name: "GD1 TOXIC LASER LOS GAS DETECTOR",
            description: "The Simtronics GD1 sets a new standard for toxic gas detection. Using a tuneable laser diode, the GD1 delivers enhanced coverage and fail-safe detection. The performance improvement marks a genuine step change for safety systems and life cycle cost savings.",
            url: "https://www.teledynegasandflamedetection.com/en/gas-detectors/fixed/open-path/gd1",
            image: "simtronics-4.jpg"
          }]
        },
        {
          name: "Flame Detectors",
          products: [{
            name: "MULTIFLAME DF-TV7-T MULTI-SPECTRUM FLAME DETECTOR",
            description: "The Simtronics DF-TV7–T is a multi-spectrum infrared flame detector using three wavelengths. DF-TV7-3IR responds to hydrocarbon fires and is the right choice for operation in dirty environments or for detection of smoky fires.",
            url: "https://www.teledynegasandflamedetection.com/en/flame-detectors/fixed/df-tv7-series",
            image: "simtronics-5.jpg"
          }]
        }]
      },
      {
        name: "micropack",
        description: "Micropack offers visual fire detectors that cannot be blinded or cause false alarms even when installed in harsh environments.",
        longDescription: "Micropack offers visual fire detectors that cannot be blinded or cause false alarms even when installed in harsh environments. The company designs, develops and manufactures the FDS product range of Visual Flame Detectors and is the market leader in the provision of Fire and Gas Mapping Studies.",
        logo: "micropack.jpg",
        route: { name: 'Product', params: { brand: 'micropack' } },
        catalogs: [{
          name: "Flame Detector",
          products: [{
            name: "FDS301 VISUAL FLAME DETECTOR",
            description: "The MICROPACK FDS301 is an explosion proof visual flame detector. It processes live video images to detect the characteristic properties of flames visually, by means of its FM and SIL 2 certified flame detection algorithms and on-board digital signal processing. It is the safest and most advanced flame detector on the market today, and its installation track record in the harshest of locations around the globe has proven the instrument to be extremely robust.",
            url: "https://www.micropackfireandgas.com/flame-detection/intelligent-visual-flame-detection-with-video-fds301",
            image: "micropack-1.jpg"
          },
          {
            name: "FDS303 MULTI-SPECTRUM IR FLAME DETECTOR",
            description: "The MICROPACK FDS303 is an explosion-proof multi-spectrum IR flame detector. The device delivers superior performance, responding to hydrocarbon liquid fuel and gas fires at long distances.",
            url: "https://www.micropackfireandgas.com/flame-detection/intelligent-visual-flame-detection-with-video-fds301",
            image: "micropack-2.jpg"
          },
          {
            name: "FDS300 INTELLIGENT FLAME DETECTOR",
            description: "The MICROPACK FDS300 is an explosion-proof visual flame detector. It processes live video images to detect the characteristic properties of flames visually, by means of its flame detection algorithms and on-board digital signal processing.",
            url: "https://www.micropackfireandgas.com/flame-detection/intelligent-visual-flame-detection-with-video-fds301",
            image: "micropack-3.jpg"
          }]
        },
        {
          name: "Flame Simulator",
          products: [{
            name: "FS301 FLAME SIMULATOR",
            description: "The MICROPACK FS301 Flame Simulator is designed to test the correct operation of Visual Flame detectors. The FS301 Flame Simulator produces a unique flame pattern that generates an accurate flame simulation capable of alerting the Visual Flame detector to triggers its alarm.",
            url: "https://www.micropackfireandgas.com/flame-detection/fs301-flame-simulator",
            image: "micropack-4.jpg"
          }]
        }]
      },
      {
        name: "spectrex",
        description: "Spectrex is a world leader in Optical Flame Detection and Open Path Gas Detection.",
        longDescription: "Spectrex is a world leader in Optical Flame Detection and Open Path Gas Detection. They were the first to launch major innovations that have become trade standards such as the first UV/IR and IR3 Flame Detector designs, with continuing new designs meeting application challenges from Offshore to Mall Floors. They were also the first to incorporate the Xenon Flash in OPGD detectors to solve false alarm issues common in the industry.",
        logo: "spectrex.png",
        route: { name: 'Product', params: { brand: 'spectrex' } },
        catalogs: [{
          name: "Open Path Gas Detector",
          products: [{
            name: "HYDROCARBONS OPEN PATH GAS DETECTOR",
            description: 'Safe "Flash Source" system, used in the 900 Series, offers well-proven technology for fast detection of combustible gases and vapours over a "Line of Sight" up to 660 ft(200m) long and are built for reliability and continued operation under extreme environmental conditions.',
            url: "https://www.spectrex.net/en-us/flame-gas-detectors/gas-detectors/open-path-gas-detector-hydrocarbons/safeye-quasar-900",
            image: "spectrex-1.jpg"
          }]
        }]
      },
      {
        name: "hikvision",
        description: "Hikvision advances the core technologies of audio and video encoding, image processing, and related data storage.",
        longDescription: "Hikvision advances the core technologies of audio and video encoding, video image processing, and related data storage, as well as forward-looking technologies such as artificial intelligence, cloud computing and big data.",
        logo: "hikvision.png",
        route: { name: 'Product', params: { brand: 'hikvision' } },
        catalogs: [{
          name: "Thermal Product",
          products: [{
            name: "HIKVISION EXPLOSION PROOF THERMAL BULLET NETWORK CAMERA",
            description: "Uses a vanadium oxide uncooled focal plane sensor to deliver enhanced thermal image quality. It also uses a smart fire detection algorithm to identify potential fire risks by monitoring irregular temperature rises in a sensitive area.",
            url: "https://www.hikvision.com/europe/products/Thermal-Products/Thermography-thermal-cameras/Special-industry-series/DS-2TD2466T-25X/",
            image: "hikvision-1.jpg"
          }]
        }]
      },
      // {
      //   name: "flowserve",
      //   description: "Flowserve manufactures and services fluid motion control solutions for the world's most demanding and critical applications.",
      //   longDescription: "The Flowserve Corporation is an American multinational corporation and one of the largest industrial and environmental machinery suppliers for fluid motion control solutions for the world's most demanding and critical applications.",
      //   logo: "flowserve.jpg",
      //   route: { name: 'Product', params: { brand: 'flowserve' } },
      //   url: "https://www.flowserve.com/en"
      // },
      {
        name: "konutherm",
        description: "Konutherm is a professional in designing high-quality thermal oil and glycol/water heat transfer systems for the marine, oil & gas and petrochemical industries.",
        longDescription: "Konutherm has over 40 years of experience in designing high-quality thermal oil and glycol/water heat transfer systems for the marine, oil & gas and petrochemical industries, and is a leading specialist in high-performance thermal oil heating systems.",
        logo: "konutherm.jpg",
        route: { name: 'Product', params: { brand: 'konutherm' } },
        url: "https://www.konutherm.com/en/"
      },
      // {
      //   name: "hammond",
      //   description: "Hammond engineers and manufactures a wide range of standard and custom transformers exported globally in electrical equipment and systems.",
      //   longDescription: "HPS is the largest manufacturer of dry-type transformers in North America. HPS engineers and manufacture a wide range of standard and custom transformers exported globally in electrical equipment and systems, supporting solid industries such as oil and gas, mining, steel, waste and water treatment, and wind power generation.",
      //   logo: "hammond.jpg",
      //   route: { name: 'Product', params: { brand: 'hammond' } },
      //   catalogs: [{
      //     name: "Dry-type Transformers and Reactors",
      //     products: [{
      //       name: "HAMMOND STANDARD PRODUCTS",
      //       description: "HPS Standard Products are available across 4 categories: Control and Automation, Low Voltage Distribution, Medium Voltage Distribution, and Potted & Specialty.",
      //       url: "http://www.hammondpowersolutions.com/our-products/",
      //       image: "hammond-1.png"
      //     }]
      //   }]
      // }
    ]
  },
  getters: {
    getBrandInfoByName: (state) => (name) => {
      return state.brand.filter(brand => brand.name == name)
    }
  }
}