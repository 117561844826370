<template>
  <base-modal @close-modal="$emit('close-modal')">
    <template v-slot:header-title>
      <div>ISO 9001:2015</div>
    </template>
    <template v-slot:default>
      <div>
        <img
          :src="
            require('@/assets/internal/document/primaservis-iso-english.jpg')
          "
          style="width: 100%"
        />
      </div>
    </template>
  </base-modal>
</template>

<script>
import BaseModal from "@/components/BaseModal.vue";

export default {
  name: 'ModalIso',
  components: { BaseModal },
};
</script>

<style lang="scss" scoped>
</style>