<template id="app">
  <div :class="'wrapper ' + freezeScroll()">
    <div class="app-header">
      <Header />
    </div>
    <div class="app-content" id="content">
      <router-view :is-mobile-view="isMobileView" />
    </div>
    <div class="app-footer">
      <Footer
        @set-active-modal-component="setActiveModalComponent"
      />
    </div>
    <transition name="modal">
      <component
        v-if="activeModalComponent"
        @close-modal="closeModal"
        :is="activeModalComponent"
      ></component>
    </transition>
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import ModalIso from "./components/ModalIso.vue";

export default {
  name: "App",
  components: { Header, Footer, ModalIso },
  data() {
    return {
      isModalVisible: false,
      activeModalComponent: "",
      isMobileView: null,
      windowWidth: null,
      defaultMobileMaxWidth: 800,
    };
  },
  created() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
  },
  methods: {
    checkScreen() {
      let mobileMaxWidth = this.mobileMaxWidth
        ? this.mobileMaxWidth
        : this.defaultMobileMaxWidth;
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= mobileMaxWidth) {
        this.isMobileView = true;
        return;
      }
      this.isMobileView = false;
    },
    freezeScroll() {
      return this.isModalVisible ? "freeze" : "";
    },
    closeModal() {
      this.activeModalComponent = "";
    },
    openModal() {
      this.isModalVisible = true;
    },
    setActiveModalComponent(componentName) {
      this.isModalVisible = true;
      this.activeModalComponent = componentName;
    },
  },
  watch: {
    $route(to, from) {
      this.activeModalComponent = "";
    },
  },
};
</script>

<style lang="scss">
@import url(https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Zen+Kaku+Gothic+New:wght@300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap);

html,
body {
  margin: 0;
  height: 100%;
}

#app {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.wrapper {
  min-height: 100%;
  position: relative;
}

// .freeze {
//   overflow-y: hidden;
// }

.app-header {
  position: fixed;
  width: 100%;
  background-color: #fff;
  z-index: 100;
}

.app-content {
  padding-top: 80px;
  min-height: 70%;
}

.app-footer {
  margin-top: auto;
}

.modal-leave-active {
  transition: all 0.8s ease;
}

.modal-leave-to {
  opacity: 0;
}

/*scroll-bar*/
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #333;
}

::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background: #0099dd;
}
</style>
