<template>
  <div class="about-page">
    <BannerSmall />
    <BannerBig />
  </div>
</template>

<script>
import BannerBig from "@/components/BannerBig.vue";
import BannerSmall from "@/components/BannerSmall.vue"
export default {
  name: "About",
  props: {
    isMobileView: Boolean,
  },
  components: { BannerBig, BannerSmall },
};
</script>

<style lang="scss" scoped>
.about-page {

  .about-page-content {
    background-color: #fff;
    max-width: 1500px;
    margin: auto;
  }
}
</style>
