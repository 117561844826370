import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronLeft, faChevronRight, faPhoneSquareAlt, faEnvelopeSquare, faCaretDown, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faChevronLeft)
library.add(faChevronRight)
library.add(faPhoneSquareAlt)
library.add(faEnvelopeSquare)
library.add(faCaretDown)
library.add(faTimes)

createApp(App)
  .component("font-awesome-icon", FontAwesomeIcon)
  .use(router)
  .use(store)
  .mount('#app')