export default {
  state: {
    data: [
      {
        title: "Home",
        routeName: "Home"
      },
      {
        title: "Product",
        routeName: "Product"
      },
      {
        title: "References",
        routeName: "References"
      },
      // {
      //   title: "About Us",
      //   routeName: "About"
      // },
      {
        title: "Contact",
        routeName: "Contact"
      }
    ]
  }
}