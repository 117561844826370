<template>
  <div class="container">
    <div class="header">
      <img
        :src="require('../assets/internal/banner/north-belut-background.jpg')"
      />
      <div class="header-content">
        <div class="welcome">Welcome To</div>
        <div class="company">Primaservis Migasindo</div>
        <div class="description">
          Established in 2003, PT Primaservis has been a major player as the
          Flame & Gas detection and CCTV specialist for hazardous areas. We
          perform the retrofitting and supply of new systems to major oil and
          gas companies in Indonesia.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BannerBig",
  computed: {
    bannerHeader() {
      return {
        // backgroundImage: `url${require('../assets/internal/banner/site-1.png')}`
        backgroundImage: `url(${require("@/assets/internal/banner/north-belut-background.jpg")})`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
$mobile-max-width: 800px;

.header {
  position: relative;
  height: 500px;

  @media (max-width: $mobile-max-width) {
      height: 400px;
    }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(0.25);
  }
}

.header-content {
  position: absolute;
  color: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 1500px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-shadow: 1px 1px 2px #000;

  .welcome {
    font: 28px "Zen Kaku Gothic New";

    @media (max-width: $mobile-max-width) {
      font: 18px "Zen Kaku Gothic New";
    }
  }

  .company {
    font: 600 60px "Zen Kaku Gothic New";

    @media (max-width: $mobile-max-width) {
      font: 600 28px "Zen Kaku Gothic New";
    }
  }

  .description {
    padding: 30px;
    font: 28px "Zen Kaku Gothic New";

    @media (max-width: $mobile-max-width) {
      font: 18px "Zen Kaku Gothic New";
    }
  }
}
</style>