<template>
  <div class="footer">
    <div class="content">
      <div class="company-section section">
        <div class="name">PT. Primaservis Migasindo</div>
        <!-- <div class="iso" @click="$emit('set-active-modal-component', 'ModalIso')">ISO 9001:2015 certified in our business field</div> -->
        <div>“Your safety is of paramount importance to us.”</div>
      </div>
      <div class="product-section section">
        <div class="title">OUR PRODUCTS</div>
        <div class="product-list">
          <div v-for="(brand, index) in brandList" :key="brand" :index="index">
            <router-link
              :to="{ name: 'Product', params: { brand: brand.name } }"
            >
              {{ brand.name }}
            </router-link>
          </div>
        </div>
      </div>
      <div class="contact-section section">
        <div class="title">CONTACT US</div>
        <div class="contact-information">
          <div class="address">
            <div>Jl. Plaju No.6</div>
            <div>Jakarta 10230, Indonesia</div>
          </div>
          <div class="phone">
            <font-awesome-icon
              class="menu-icon"
              icon="phone-square-alt"
            />&nbsp;
            <a href="tel:+622131930801">+62 21 31930801</a>
            /
            <a href="tel:+622131930882">+62 21 31930882</a>
          </div>
          <div class="email">
            <font-awesome-icon class="menu-icon" icon="envelope-square" />&nbsp;
            <a href="mailto:info@primaservis.com">info@primaservis.com</a>
          </div>
        </div>
      </div>
    </div>
    <div class="copywrite">
      <div>Copyright © {{year}} PT. Primaservis Migasindo</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      year: 2021,
    }
  },
  created(){
    this.year = new Date().getFullYear();
  },
  computed: {
    brandList() {
      return this.$store.state.product.brand;
    },
  },
};
</script>

<style lang="scss" scoped>
$mobile-max-width: 800px;

.footer {
  background-color: #fafafa;
  font: 400 18px "Zen Kaku Gothic New";
  color: #333;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: $mobile-max-width) {
    font: 400 16px "Zen Kaku Gothic New";
  }

  .content {
    max-width: 1500px;
    width: 100%;
    margin: auto;
    display: flex;
    padding: 30px;

    > div {
      &:not(:last-child) {
        border-right: 1px solid #ddd;

        @media (max-width: $mobile-max-width) {
          border-right: none;
          padding-bottom: 30px;
        }
      }
    }

    @media (max-width: $mobile-max-width) {
      flex-direction: column;
      justify-content: center;
      text-align: center;
      padding: 20px 0px;
    }
  }

  .copywrite {
    border-top: 1px solid #ddd;
    padding: 10px;
    font: 500 16px "Zen Kaku Gothic New";
    display: flex;
    justify-content: center;

    div {
      max-width: 1500px;
      margin: auto;
    }
  }
}

.title {
  text-transform: uppercase;
  font: 600 20px "Zen Kaku Gothic New";
  padding-bottom: 10px;

  @media (max-width: $mobile-max-width) {
    font: 600 18px "Zen Kaku Gothic New";
  }
}

.section {
  padding: 0 20px;

  a {
    color: #004499;
    text-decoration: none;
  }
}

.company-section {
  flex: 1;
  .name {
    font: 600 24px "Zen Kaku Gothic New";
  }

  .iso {
    color: #004499;
    cursor: pointer;
    font-weight: 500;
  }
}

.product-section {
  flex: 1;
  .product-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px 10px;

    a {
      text-transform: uppercase;
      font: 400 16px "Zen Kaku Gothic New";
    }
  }
}

.contact-section {
  flex: 1;
}

.contact-information {
  .address {
    padding-bottom: 10px;
  }
}
</style>