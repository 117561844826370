import { createStore } from 'vuex'
import menu from './data/menu.js'
import product from './data/product.js'
import references from './data/references.js'

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    menu: menu,
    product: product,
    references: references,
  }
})
