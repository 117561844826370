export default {
  state: {
    companies: [{
      name: "Pertamina EP Cepu",
      logo: "pertamina-ep-cepu.jpg"
    },
    {
      name: "Pertamina PHE OSES",
      logo: "pertamina-phe-oses.png"
    },
    // {
    //   name: "Pertamina Hulu Energi",
    //   logo: "pertamina-hulu-energi.png"
    // },
    {
      name: "Pertamina Hulu Mahakam",
      logo: "pertamina-hulu-mahakam.png"
    },
    {
      name: "Pertamina PHE ONWJ",
      logo: "pertamina-phe-onwj.jpg"
    },
    {
      name: "Pertamina PHE NSO",
      logo: "pertamina-phe-nso.png"
    },
    {
      name: "Pertamina Refinery Unit V Balikpapan",
      logo: "pertamina-balikpapan.jpg"
    },
    {
      name: "BP",
      logo: "bp.png"
    },
    {
      name: "Harbour Energy",
      logo: "harbour-energy.png"
    },
    {
      name: "Star Energy",
      logo: "star-energy.jpg"
    },
    {
      name: "CITIC Seram Energy Limited",
      logo: "citic.png"
    },
    {
      name: "ConocoPhillips",
      logo: "conocophillips.png"
    },
    {
      name: "MedcoEnergi",
      logo: "medcoenergi.png"
    },
    {
      name: "PLN",
      logo: "pln.png"
    }]
  }
}