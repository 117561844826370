<template>
  <div class="container" :style="cssProps">
    <transition name="header" appear>
      <div class="header">
        <img v-if="!bannersPath" :src="bannerImage" />
        <div class="header-images" v-show="bannersPath">
          <div
            class="header-image"
            v-for="(path, index) in bannersPath"
            :key="path"
            :index="index"
          >
            <transition name="header-image">
              <div v-show="activeBanner(index)">
                <img :src="getBannerImage(path)" />
              </div>
            </transition>
          </div>
        </div>
        <div class="header-content">
          <div v-if="openingText" class="opening">{{ openingText }}</div>
          <div v-if="titleText" class="title">{{ titleText }}</div>
          <div v-if="descriptionText" class="description">
            {{ descriptionText }}
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "BannerSmall",
  props: {
    bannerPath: String,
    bannersPath: Array,
    openingText: String,
    titleText: String,
    descriptionText: String,
    brightness: String,
  },
  data() {
    return {
      activeBannerIndex: 0,
      timer: null,
    };
  },
  mounted() {
    if (this.bannersPath) {
      this.timer = setInterval(this.next, 5000);
    }
  },
  computed: {
    bannerImage() {
      let image = this.bannerPath
        ? require("@/assets/internal/banner/" + this.bannerPath)
        : require("@/assets/internal/banner/oil-rig.jpg");
      return image;
    },

    cssProps() {
      // Creating variable for CSS
      let brightness = this.brightness ? this.brightness : 1;
      return {
        "--brightness": brightness,
      };
    },
  },
  methods: {
    getBannerImage(path) {
      return require("@/assets/internal/banner/" + path);
    },
    activeBanner(index) {
      return this.activeBannerIndex === index;
    },
    next() {
      if (this.activeBannerIndex != this.bannersPath.length - 1) {
        this.activeBannerIndex += 1;
      } else {
        this.activeBannerIndex = 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$mobile-max-width: 800px;

.header-enter-from {
  transform: translateY(-300px);
  opacity: 0;
}

.header-enter-active {
  transition: all 1s ease;
}

.header {
  position: relative;
  height: 250px;

  @media (max-width: $mobile-max-width) {
    height: 150px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(var(--brightness));
  }

  .header-images {
    width: 100%;
    height: 100%;

    div {
      width: 100%;
      height: 100%;
    }

    .header-image {
      position: absolute;
    }
  }
}

.header-image-enter-active, .header-image-leave-active{
  transition: all 2s ease;
}

.header-image-enter-from, .header-image-leave-to{
  opacity: 0;
}

.header-content {
  position: absolute;
  color: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 1500px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-shadow: 1px 1px 2px #000;

  .opening {
    font: 28px "Zen Kaku Gothic New";

    @media (max-width: $mobile-max-width) {
      font: 18px "Zen Kaku Gothic New";
    }
  }

  .title {
    font: 600 48px "Zen Kaku Gothic New";

    @media (max-width: $mobile-max-width) {
      font: 600 24px "Zen Kaku Gothic New";
    }
  }

  .description {
    padding-top: 20px;
    font: 28px "Zen Kaku Gothic New";
    text-align: center;

    @media (max-width: $mobile-max-width) {
      font: 18px "Zen Kaku Gothic New";
      padding: 5px 20px;
    }
  }
}
</style>