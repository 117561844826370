<template>
  <div class="product-page">
    <BannerSmall 
    :banner-path="bannerPath"
    :banners-path="bannersPath"
    :title-text="titleText"
    :description-text="descriptionText"
    brightness="0.5"
    v-if="!childPage"
    />
    <!-- <div class="product-header" v-if="!childPage">
      <div class="header-container">
        <div class="header-container image">
          <div>
            <img src="../assets/internal/banner/installation-cctv.png" />
            <img src="../assets/internal/banner/installation-micropack.png" />
          </div>
        </div>
        <div class="header-container content">
          The product we offer represent cutting-edge technology, brought by
          various leading specialist companies.
        </div>
      </div>
    </div> -->
    <div class="product" v-if="!childPage">
      <div class="product-container">
        <div v-for="brand in brandList" :key="brand" class="product-item">
          <transition name="product-detail" appear>
            <div class="product-detail">
              <div class="product-image">
                <div class="container">
                  <img :src="getBrandLogo(brand.logo)" />
                </div>
              </div>
              <div class="product-content">
                <div class="name">{{ brand.name }}</div>
                <div class="description">{{ brand.description }}</div>
                <div class="product-action">
                  <router-link
                    class="link"
                    :to="{ name: 'Product', params: { brand: brand.name } }"
                    >View Products</router-link
                  >
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <div class="brand" v-if="childPage">
      <div class="navigation">
        <BrandNavigation :brand-list="brandList" :is-mobile-view="isMobileView" />
      </div>
      <div class="detail">
        <BrandDetail :brand-info="brandInfo" />
      </div>
    </div>
  </div>
</template>

<script>
import BrandDetail from "@/components/BrandDetail.vue";
import BrandNavigation from "@/components/BrandNavigation.vue";
import BannerSmall from "@/components/BannerSmall.vue"

export default {
  name: "Product",
  props: {
    isMobileView: Boolean,
  },
  components: { BrandDetail, BrandNavigation, BannerSmall },
  data() {
    return {
      bannerPath: "installation-micropack.png",
      bannersPath: ["simtronics-banner.jpg","micropack-banner.jpg", "custom-banner.jpg"],
      titleText: "Our Products",
      descriptionText: "brought by leading specialist companies."
    };
  },
  created() {},
  computed: {
    childPage() {
      return typeof this.$route.params.brand !== "undefined"
        ? this.$route.params.brand
        : false;
    },
    brandInfo() {
      let name = this.$route.params.brand;
      return this.$store.getters.getBrandInfoByName(name)[0];
    },
    brandList() {
      return this.$store.state.product.brand;
    },
  },
  methods: {
    getBrandLogo(path) {
      return require("../assets/internal/logo/" + path);
    },
  },
};
</script>

<style lang="scss" scoped>
$mobile-max-width: 800px;

.product-page {
  display: flex;
  flex-direction: column;
}

.product-header {
  display: flex;
  background-color: #333;
  max-width: 1500px;
  margin: auto;
}

.header-container {
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media (max-width: $mobile-max-width) {
    flex-direction: column;
  }

  &.image {
    flex: 2;
    border: 2px solid #ddd;
    padding: 0px;

    img {
      width: 50%;

      @media (max-width: $mobile-max-width) {
        flex-direction: column;
      }
    }
  }

  &.content {
    flex: 3;
    text-align: right;
    padding: 20px;
    color: #ddd;
    font: 32px "DM Serif Display";

    @media (max-width: $mobile-max-width) {
      font: 16px "DM Serif Display";
    }
  }
}

.product {
  display: flex;
  background-color: #fff;
  max-width: 1500px;
  margin: auto;
}

.product-container {
  padding: 60px;
  // width: 100%;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 50px;

  @media (max-width: $mobile-max-width) {
    grid-template-columns: auto;
    padding: 30px;
    grid-gap: 30px;
  }
}

.product-item {
  display: flex;
  flex-direction: column;

  .product-detail-enter-from {
    opacity: 0;
    transform: scale(0.9);
  }

  .product-detail-enter-active {
    transition: all 1.2s ease;
  }

  .product-detail {
    flex: 2;
    display: flex;
    flex-direction: row;
    background-color: #fff;
    box-shadow: 0 0 15px #ccc;
    padding: 10px;
    border-radius: 10px;

    .product-image {
      flex: 1;
      padding: 10px;

      .container {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 100%; //very important for responsive webpage
          max-height: 200px;
        }
      }
    }
    .product-content {
      border-left: 1px solid #045269;
      flex: 2;
      padding: 10px;
      // position: relative; //used to make .view-detail on the bottom of .product-content, used together with position: absolute on .view-detail
      display: flex;
      flex-direction: column;

      .name {
        flex: 1;
        font: 26px "Oswald";
        text-transform: uppercase;
        padding: 10px;
        color: #045269;

        @media (max-width: $mobile-max-width) {
          font: 18px "Oswald";
        }
      }

      .description {
        flex: 2;
        font: 400 18px "Zen Kaku Gothic New";
        padding: 10px 10px 20px 10px;

        @media (max-width: $mobile-max-width) {
          font: 14px "Zen Kaku Gothic New";
        }
      }

      .product-action {
        flex: 0 30px;
        background-color: rgb(245, 163, 1);
        background-color: rgb(250, 227, 182);
        padding: 2px 10px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        margin-left: auto;

        .link {
          text-decoration: none;
          text-transform: uppercase;
          color: black;
          font: 500 16px "Zen Kaku Gothic New";
          vertical-align: middle;

          @media (max-width: $mobile-max-width) {
            font: 12px "Zen Kaku Gothic New";
          }
        }
      }
    }
  }
}

.brand {
  display: flex;
  flex-direction: column;
  background-color: #fff;

  .navigation {
    display: flex;
    max-width: 1500px;
    margin: auto;
    width: 100%;
    background-color: #fff;
  }

  .detail{
    display: flex;
    flex-direction: column;
    // max-width: 1500px;
    // margin: auto;
    width: 100%;
    background-color: #fff;
  }
}
</style>