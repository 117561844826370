<template>
  <div class="container">
    <div class="header">
      <div class="content">
        <div class="description"></div>
        <div class="image"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BannerDual",
};
</script>

<style lang="scss" scoped>
.header {
  position: relative;
  max-width: 1500px;
  margin: auto;
}

.content {
  display: flex;
  flex-direction: row;
  padding: 10px;

  .description {
    flex: 3;
  }

  .image {
    flex: 2;
  }
}
</style>