<template>
  <div class="modal-backdrop">
    <div class="modal-backdrop-close" @click="$emit('close-modal')"></div>
    <transition name="modal" appear>
      <div class="modal">
        <div class="modal-content">
          <div class="header">
            <slot name="header-title"></slot>
          </div>
          <div class="body">
            <slot></slot>
          </div>
        </div>
        <font-awesome-icon
          class="close-modal"
          icon="times"
          @click="$emit('close-modal')"
        />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "BaseModal",
};
</script>

<style lang="scss" scoped>
$mobile-max-width: 800px;

.modal-backdrop {
  font: 20px "Zen Kaku Gothic New";
  position: fixed;
  z-index: 200;

  /* full screen */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.25);

  display: flex;

  .modal-backdrop-close {
    height: 100%;
    width: 100%;
    position: absolute;
  }
}

.modal {
  max-width: 1500px;
  height: 80%;
  // width: 100%;
  margin: auto;
  background-color: #fff;
  position: relative;

  @media (max-width: $mobile-max-width) {
    max-width: 90%;
  }

  .close-modal {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    border-radius: 20px;
    padding: 10px;
    height: 20px;
    width: 20px;
    text-align: center;
    margin-top: -20px;
    margin-right: -20px;
  }
}

.modal-enter-from {
  transform: scaleX(0);
}

.modal-enter-active {
  transition: all 1s ease;
}

.modal-content {
  background-color: #fff;
  padding: 40px;
  height: calc(100% - 80px);

  @media (max-width: $mobile-max-width) {
    padding: 15px;
    height: calc(100% - 30px);
  }
}

.header {
  height: 60px;
  display: flex;
  align-items: center;

  font: 600 28px "Zen Kaku Gothic New";
  padding-bottom: 20px;

  @media (max-width: $mobile-max-width) {
    font: 600 20px "Zen Kaku Gothic New";
    height: 40px;
    padding-bottom: 0px;
  }
}

.body {
  height: calc(100% - 80px); //subtract by sticky header & padding size
  overflow-y: auto;

  @media (max-width: $mobile-max-width) {
    font: 16px "Zen Kaku Gothic New";
    height: calc(100% - 40px);
  }
}

::-webkit-scrollbar-track {
  background: #fff;
}
</style>