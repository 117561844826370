<template>
  <div class="carousel" :style="cssProps">
    <div class="inner" ref="inner">
      <transition-group :name="direction">
        <div :class="'list-item ' + getBannerPosition(index)" v-for="index in activeContents" :key="index">
          <router-link v-show="data[index].route" :to="data[index].route">
            <img :src="getImgUrl(data[index].path)"/>
          </router-link>
          <img v-show="!data[index].route" :src="getImgUrl(data[index].path)"/>
        </div>
      </transition-group>
      <button class="banner-button left" v-on:click="manualPrev">
        <font-awesome-icon class="menu-icon" icon="chevron-left" />
      </button>
      <button class="banner-button right" v-on:click="manualNext">
        <font-awesome-icon class="menu-icon" icon="chevron-right" />
      </button>
    </div>
  </div>
  <div class="bullets">
    <transition-group name="bullet">
      <div :class="'bullet-item ' + getBulletState(index)" v-for="(content, index) in data" :key="content" :index="index">
      </div>
    </transition-group>
  </div>
</template>

<script>
export default {
  name: 'Carousel',
  props: {
    "bannerWidth": Number, 
    "imageWidth" : Number,
    "bulletActiveColor": String,
    "bulletInactiveColor": String,
    "imageData" : Object,
    "directory" : String
  },
  data() {
    return {
      data: this.imageData,
      activeContents: [],
      direction: "next",
      timer: null,
    }
  },
  beforeMount(){
    this.activeContents[0] = this.data.length - 1
    this.activeContents[1] = 0
    this.activeContents[2] = 1
  },
  mounted(){
    this.autoNext();
  },
  computed: {
    cssProps() { // Creating variable for CSS
      let bannerWidth = this.bannerWidth ? this.bannerWidth : 1040
      let imageWidth = this.imageWidth ? this.imageWidth : 500
      let itemWidth = imageWidth + 20
      return {
        '--banner-width': bannerWidth + "px",
        '--image-width': imageWidth + "px",
        '--item-width': itemWidth + "px",
      }
    },
    getMiddleIndex(){
      return this.activeContents[1]
    },
  },
  methods: {
    getImgUrl(path){
      return require('../' + this.directory + path)
    },
    getBannerPosition(index){
      return index === 0? "left" : index === 1? "middle" : "right"
    },
    prev() {
      let newContentIndex = this.data.length - 1
      if (this.activeContents[0] != 0){
        newContentIndex = this.activeContents[0] - 1
      }
      this.direction = "prev"
      this.activeContents.splice(2, 1)
      this.activeContents.splice(0, 0, newContentIndex)
    },
    next() {
      let newContentIndex = 0
      if (this.activeContents[2] != this.data.length - 1){
        newContentIndex = this.activeContents[2] + 1
      }
      this.direction = "next"
      this.activeContents.splice(0,1)
      this.activeContents.push(newContentIndex)
    },
    manualPrev(){
      this.prev()
      clearInterval(this.timer)
      this.autoNext()
    },
    manualNext(){
      this.next()
      clearInterval(this.timer)
      this.autoNext()
    },
    autoNext() {
      this.timer = setInterval(this.next, 5000)
    },
    getBulletState(index) {
      if (index == this.getMiddleIndex){
        return 'active'
      }
      else {
        return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.carousel{
  display: flex;            //display flex allow us to centralized div these 2 properties
  align-items: center;      //vertical alignment
  justify-content: center;  //horizontal alignment
  min-height: 250px;
}

.inner{
  width: var(--banner-width);          //determine the size of banner
  // height: 100%;
  position: relative;
  overflow: hidden;       //trim the banner outside the width
  
  display: flex;            //display flex allow us to order the banner in horizontal axis
  justify-content: center;  //horizontal alignment
}

.banner-button{
  position: absolute;
  top: calc(50% - 25px);
  height: 50px;
  width: 50px;
  background-color: rgb(25, 4, 44);
  color: #fff;
  border: none;
  border-radius: 25px;
  opacity: 0.6;
  font-size: 22px;

  &:hover{
    cursor: pointer;
  }

  &.left{
    left: 10px;
  }

  &.right{
    right: 10px;
  }
}

.list-item{
  transition: all 1s ease;
  display: inline-flex;
  width: calc(var(--image-width) + 20);
  justify-content: center; 
}

.next-enter-from{
  transform: translateX(calc(var(--image-width) * 1.1)) scale(0.8);
  opacity: 0;
}

.next-leave-to{
  transform: translateX(calc(var(--image-width) * -2.2)) scale(0.8);
  opacity: 0;
}

.next-leave-active{
  position: absolute;
}

.prev-enter-from{
  transform: translateX(calc(var(--image-width) * -1.1)) scale(0.8);
  opacity: 0;
}

.prev-leave-to{
  transform: translateX(calc(var(--image-width) * 2.2)) scale(0.8);
  opacity: 0;
}

.prev-leave-active{
  position: absolute;
}

img{
  border-radius: 20px;
  width: var(--image-width);
  // height: 100%;
  margin: 0 10px;
  background-color: #ffffff;
}

.bullets{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}

.bullet-item{
  background-color: rgb(236, 50, 55);
  background-color: rgb(254, 138, 17);
  width: 15px;
  height: 15px;
  margin: 5px;
  border-radius: 10px;
  transition: all 0.8s ease;
}

.bullet-active{
  position: absolute
}

.active{
  background-color: rgb(245, 163, 1);
  background-color: rgb(248, 214, 147);
  width: 40px;
}

</style>