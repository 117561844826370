<template>
  <div class="home">
    <transition name="company" appear>
      <BannerBig />
    </transition>
    <transition name="product-list" appear>
      <div class="product-list">
        <div class="title">
          <div class="intro">We have a wide range of products</div>
          <div class="outro">
            brought by top leading specialist companies to meet your business
            demand
          </div>
        </div>
        <Carousel
          :image-data="productCarouselData"
          :image-width="250"
          :banner-width="1400"
          :directory="'assets/internal/logo/'"
        />
        <div class="foot-note">
          <router-link :to="{ name: 'Product' }" class="view-product">
            <div>View All Products</div>
          </router-link>
        </div>
      </div>
    </transition>
    <!-- <div class="qualification">
      <BannerDual />
    </div> -->
  </div>
  <div class="debug"></div>
</template>

<script>
// @ is an alias to /src
import Carousel from "@/components/Carousel.vue";
import BannerBig from "@/components/BannerBig.vue";
import BannerDual from "@/components/BannerDual.vue"

export default {
  name: "Home",
  props: {
    isMobileView: Boolean,
  },
  components: {
    Carousel,
    BannerBig,
    BannerDual,
  },
  data() {
    return {
      productCarouselData: []
    };
  },
  created() {
    let products = this.$store.state.product.brand;
    let paths = [];
    let data = [];
    products.forEach(function (product) {
      paths.push(product.logo);
      data.push({path: product.logo, route: product.route})
    });
    this.productPaths = paths;
    this.productCarouselData = data;
  },
};
</script>

<style lang="scss" scoped>
$mobile-max-width: 800px;

img {
  width: 100%;
  opacity: 0.7;
}

.product-list {
  background-color: #045269;
  padding: 50px;

  @media (max-width: $mobile-max-width) {
    padding: 20px;
  }

  .title {
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    @media (max-width: $mobile-max-width) {
      padding-bottom: 15px;
    }

    .intro {
      font: 36px "Oswald";
      text-transform: uppercase;
      color: #ffffff;

      @media (max-width: $mobile-max-width) {
        font: 22px "Oswald";
      }
    }

    .outro {
      font: 28px "Zen Kaku Gothic New";
      color: #dddddd;

      @media (max-width: $mobile-max-width) {
        font: 18px "Zen Kaku Gothic New";
      }
    }
  }

  .foot-note {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    .view-product {
      font: 600 22px "Zen Kaku Gothic New";
      text-transform: uppercase;
      color: #045269;
      background-color: #eafffc;
      padding: 8px 16px;
      border-radius: 24px;
      text-decoration: none;

      @media (max-width: $mobile-max-width) {
        font: 600 20px "Zen Kaku Gothic New";
      }
    }
  }
}

.company-enter-from {
  opacity: 0.2;
  transform: translateX(-1200px);

  @media (max-width: $mobile-max-width) {
    transform: translateX(-600px);
  }
}

.company-enter-active {
  transition: all 1s ease;
}

.product-list-enter-from {
  opacity: 0.2;
  transform: translateX(1200px);

  @media (max-width: $mobile-max-width) {
    transform: translateX(600px);
  }
}

.product-list-enter-active {
  transition: all 1s ease;
}

.home {
  overflow: hidden;
}
</style>
